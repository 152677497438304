import request from '../utils/request';

export function recordList(query) {
	return request({
		url: "whitelist/import_log_list",
		method: "get",
		params: query
	});
}
export function pihaoList(query) {
	return request({
		url: "whitelist/import_log_view",
		method: "get",
		params: query
	});
}
export function Resend(query) {
    return request({
        url: 'whitelist/resend',
        method: 'POST',
        data: query
    });
}
export function allResend(query) {
    return request({
        url: 'whitelist/batch_resend',
        method: 'POST',
        data: query
    });
}